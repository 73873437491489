import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vuetify";
import "es6-promise/dist/es6-promise.auto.min";
import "whatwg-fetch";
import RoutingErrorPage from "@/components/page/RoutingErrorPage.vue";

(window as any).RoutingError = RoutingErrorPage;

document.addEventListener("DOMContentLoaded", () => {
    const t = new RoutingErrorPage({ vuetify, el: "#app" });
});

export default RoutingErrorPage;
