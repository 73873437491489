




























import { Vue, Component, Prop } from "vue-property-decorator";
import IEcomEventInfo from "@/chipply/events/IEcomEventInfo";
import { UrlUtils, WebHelper } from "chipply-common";
import { IDealerRoutingErrorInfo } from "@/chipply/IDealerRoutingErrorInfo";

@Component({})
export default class RoutingError extends Vue {
    @Prop({
        type: Number,
    })
    public dealerId!: number;
    public info: IDealerRoutingErrorInfo | null = null;

    public async created() {
        if (this.dealerId) {
            await this.getInfo();
        }
    }
    protected async getInfo() {
        this.info = await WebHelper.getJsonData(`/api/Dealer/GetDealerRoutingErrorInfo/${this.dealerId}`);
    }
    protected redirect() {
        let isSubdomain = location.hostname.split(".").length > 2;
        let domainRedirect = isSubdomain
            ? "https://" + location.hostname.slice(location.hostname.indexOf(".") + 1)
            : "https://" + location.hostname;

        this.dealerId ? window.location.assign("../") : window.location.assign(domainRedirect);
    }
}
