





import { Vue, Component } from "vue-property-decorator";
import RoutingError from "@/components/RoutingError.vue";

@Component({
    components: { RoutingError },
})
export default class RoutingErrorPage extends Vue {
    public dealerId!: number;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("dealerid")) {
            this.dealerId = Number(urlParams.get("dealerid"));
        }
    }
}
